<template>
  <FocusTrap>
    <div id="mycard" class="card">

      <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

        <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Jobwork WorkOrder List</h5>

        <div class="header-elements">
          <form action="#">
            <div class="form-group-feedback form-group-feedback-right">
              <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
              <div class="form-control-feedback">
                <i class="icon-search4 font-size-base text-muted"></i>
              </div>
            </div>
          </form>

        </div>
      </div>

      <div class="card-body" style="padding: 0px;">
        <div class="table-responsive">
          <table id="mytable"
                 class="table  table-hover  table-bordered table-condensed table-columned"
                 data-search="false"
                 data-pagination="true"
                 data-show-refresh="false"
                 data-show-columns="false"
                 data-page-list="[10, 25, 50, 100, ALL]"
                 data-show-footer="false"
                 data-toggle="context"
                 data-target=".context-table">
            <thead>
            <tr>
              <th data-field="id" data-class="d-none">id</th>
              <th data-formatter="runningFormatter" data-width="75">S.No</th>
              <th data-field="doc_no" data-width="100" data-sortable="true">Doc No</th>
              <th data-field="doc_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Date</th>
              <th data-field="due_date" data-width="110" data-formatter="dateFormatter" data-sortable="true">Due Date</th>
<!--              <th data-field="process.name" data-sortable="true">Process</th>-->
              <th data-field="jobworker.name" data-sortable="true">Jobworker</th>
<!--              <th data-field="order_no" data-sortable="true">Order No</th>-->
              <th data-field="remarks" data-sortable="true">Remarks</th>
  <!--            <th data-field="fab_wgt" data-formatter="weightFormat" data-width="125" data-sortable="true" data-align="right">Weight</th>-->
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tfoot>
            </tfoot>
          </table>
        </div>

      </div>

      <!-- Context Menu -->
      <div id="context-menu" class="context-table">
        <div class="dropdown-menu">
          <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: blue"></i>Refresh</a>
          <div class="dropdown-divider"></div>
          <!--<a href="#" class="dropdown-item" ><i class="icon-puzzle"></i>Breakup</a>-->
          <a href="#" class="dropdown-item" ><i class="icon-pencil7" style="color: #2d7330"></i>Modify</a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item" ><i class="icon-blocked" style="color: orangered"></i>Remove</a>
        </div>
      </div>
      <!-- End of Context Menu -->


      <FocusTrap >
        <vmodal name="work_order_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="80%" height="75%" @click="showModal" @before-open="beforeOpen" @before-close="beforeClose">
          <WorkOrder  v-bind:myworkorder="voucher"></WorkOrder>
        </vmodal>
      </FocusTrap>


      <div class="modal" id="work_order_window" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog  modal-full modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">

            <!-- Modal body -->
            <div class="modal-body" style="padding: 0px">
              <WorkOrder v-if="voucher.code > 1" v-bind:myworkorder="voucher" v-on:receipt_saved="closeModal"></WorkOrder>
            </div>

          </div>
        </div>
      </div>

      <FocusTrap>
        <vmodal name="filter_window" transition="nice-modal-fade" :delay="100" :resizable="true" :width="575" height="250" @opened="afterfilterwindow_visible"  >
          <form>
            <div class="card">
              <div class="card-header header-elements-inline bg-primary" style="padding: 10px;">
                <h3 class="card-title">Filter</h3>
                <div class="header-elements">
                  <div class="list-icons">
                    <!--<a class="list-icons-item" data-action="remove" @click="closeRequest"></a>-->
                  </div>
                </div>
              </div>

              <div class="card-body">

                <p/>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">From:</label>
                  <div class="col-md-3">
                    <input id= "txtfrom" ref= "txtfrom" type="date" class="form-control"  v-model="from_date" :max="upto_date" autofocus />
                  </div>

                  <label class="col-md-3 col-form-label">Upto</label>
                  <div class="col-md-3">
                    <input type="date" class="form-control"  v-model="upto_date" :min="from_date" />
                  </div>
                </div>


                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Jobworker:</label>
                  <div class="col-md-9">
                    <select id="cmbJobworker" class="form-control" v-model="jobworker_id" >
                      <option value="0" selected>All</option>
                      <option v-for="camp in jobworkers" v-bind:value="camp.id">
                        {{ camp.name }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="text-right">
                  <button type="button" class="btn btn-outline alpha-success text-success-800 border-success-600 legitRipple" @click="loadData" >Show Data<i class="icon-database-refresh ml-2"></i></button>
                </div>

              </div>
            </div>
          </form>
        </vmodal>
      </FocusTrap>

      <!-- Clickable menu -->
      <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="0">
        <li>
          <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showFilterWindow">
            <i class="fab-icon-open icon-filter3"></i>
            <i class="fab-icon-close icon-filter4"></i>
          </a>
        </li>
      </ul>
      <!-- /clickable menu -->

    </div>
  </FocusTrap>
</template>

<script>
import WorkOrder from '@/views/fms/workorder/WorkOrderForm.vue'
import FabButton from '@/components/core/FabButton.vue'
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'WorkOrderView',
  store,
  components: {
    WorkOrder,
    FabButton,
    moment
  },
  data () {
    return {
      voucher: JSON.parse('{"id":0,"status":"Active","jobworker_id":0,"shift_id":0,"lot_id":0,"operator1_id":0,"operator2_id":0,"workorder_id":0,"qua_id":0,"col_id":0,"item_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"operator1":{"id":0,"status":"Active","emp_code":"","name":""},"operator2":{"id":0,"status":"Active","emp_code":"","name":""},"workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":""},"process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"color":{"id":0,"status":"Active","name":"","p_name":"","wgt":0},"quality":{"id":0,"name":"","p_name":""},"qty":0,"weight":0,"remarks":""}'),
      mytable: {},
      jobworker_id:0,
      jobworkers : [],
      from_date: moment().add(-7, 'days').format('YYYY-MM-DD'),
      upto_date: moment().format('YYYY-MM-DD'),
      quality_id : 0,
      qualities : [],
      color_id : 0,
      colors : [],
    }
  },
  created () {

  },
  mounted () {
    const self = this

    this.$data.mytable = $('#mytable');
    this.$data.mytable.bootstrapTable();

    $('[data-toggle="popover"]').popover();
    $('#mytable').contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {

        let id = $(row.children('*')[0]).text()
        let code = $(row.children('*')[2]).text()

        if ($(e.target).text() === 'Refresh') {
          self.loadData()
        } else if ($(e.target).text() === 'Print') {
          self.printPreview(code)
        } else if ($(e.target).text() === 'Modify') {
          self.modifyDocument(id)
        } else if ($(e.target).text() == 'Remove') {
          self.removeDocument(id)
        } else if ($(e.target).text() == 'Send To QC') {
          self.sendToQc(id)
        }

      }
    });

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase()
      $('#mytable>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
        return !~text.indexOf(val)
      }).hide()
    });

    $('[data-action=remove]').click(function (e) {
      $('#work_order_window').modal('hide')
    });

    self.loadJobworkers();
  },
  methods: {
    closeModal () {
      $('#work_order_window').modal('hide')
      this.loadData()
    },
    showFilterWindow(){
      this.$modal.show('filter_window');
    },
    afterfilterwindow_visible(){
      this.$refs.txtfrom.focus();
    },
    beforeOpen () {

    },
    beforeClose () {
      this.voucher = JSON.parse('{"id":0,"status":"Active","jobworker_id":0,"shift_id":0,"lot_id":0,"operator1_id":0,"operator2_id":0,"workorder_id":0,"qua_id":0,"col_id":0,"item_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"operator1":{"id":0,"status":"Active","emp_code":"","name":""},"operator2":{"id":0,"status":"Active","emp_code":"","name":""},"workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":""},"process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"color":{"id":0,"status":"Active","name":"","p_name":"","wgt":0},"quality":{"id":0,"name":"","p_name":""},"qty":0,"weight":0,"remarks":""}')
    },
    afterOpen () {

    },
    printPreview (id) {

      const self = this
      self.$modal.show('viewer_window')

      self.$data.voucher = self.$data.mytable.bootstrapTable('getRowByUniqueId', id)

      self.$data.voucher.date = self.$data.voucher.date.slice(0, 10).split('-').join('-') + 'T00:00:00Z'


    },
    showModal () {
      $('#work_order_window').modal('hide')
    },
    clear () {
      this.voucher = JSON.parse('{"id":0,"status":"Active","jobworker_id":0,"shift_id":0,"lot_id":0,"operator1_id":0,"operator2_id":0,"workorder_id":0,"qua_id":0,"col_id":0,"item_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"operator1":{"id":0,"status":"Active","emp_code":"","name":""},"operator2":{"id":0,"status":"Active","emp_code":"","name":""},"workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":""},"process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"color":{"id":0,"status":"Active","name":"","p_name":"","wgt":0},"quality":{"id":0,"name":"","p_name":""},"qty":0,"weight":0,"remarks":""}');
    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$data.voucher = JSON.parse('{"id":0,"status":"Active","jobworker_id":0,"shift_id":0,"lot_id":0,"operator1_id":0,"operator2_id":0,"workorder_id":0,"qua_id":0,"col_id":0,"item_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","shift":{"id":0,"type":0,"name":""},"operator1":{"id":0,"status":"Active","emp_code":"","name":""},"operator2":{"id":0,"status":"Active","emp_code":"","name":""},"workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":""},"process":{"id":0,"name":"","nature":0,"p_name":"","s_name":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"color":{"id":0,"status":"Active","name":"","p_name":"","wgt":0},"quality":{"id":0,"name":"","p_name":""},"qty":0,"weight":0,"remarks":""}');
      fetch(`${process.env.VUE_APP_ROOT_API}api/workorder/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {

            console.log(JSON.stringify(resp.data));

            self.$data.voucher = resp.data;
            self.$data.voucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');
            self.$data.voucher.due_date = moment(self.$data.voucher.due_date).format('YYYY-MM-DD');
            $('#work_order_window').modal('show')
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      })
    },
    removeDocument (id) {
      const self = this

      self.$data.voucher.id = id

      const requestOptions = {
        method: 'DELETE',
        mode: 'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.voucher)
      }

      swal({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete this!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/workorder`, requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('work_order_window');
            self.loadData();
            swal(
              'Deleted!',
              'Your request has been processed',
              'success'
            )
          }).catch(function (err) {
            swal({ title: 'Oops', text: err.toString(), type: 'error' })
          })
        }
      })

    },
    loadData () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }

      self.$modal.hide('filter_window');
      $('#txtsearch').val('');
      $(self.$data.mytable).block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      })

      self.$data.mytable.bootstrapTable('load', []);
      self.$data.mytable.bootstrapTable('showLoading');

      let filters = `from=${self.from_date}&upto=${self.upto_date}&quality_id=${self.quality}&color_id=${self.color_id}&jobworker_id=0`;

      fetch(`${process.env.VUE_APP_ROOT_API}api/workorders?${filters}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {

          if (_.isArray(resp.data)) {
            self.$data.mytable.bootstrapTable('load', resp.data);
          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error', timer:1500 })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer:1500 })
      }).finally(function () {
        self.$data.mytable.bootstrapTable('hideLoading');
        $(self.$data.mytable).unblock()
      })
    },
    loadJobworkers(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      self.$modal.show('filter_window');
      self.$data.jobworkers = [];

      fetch(`${process.env.VUE_APP_ROOT_API}api/jobworkers`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {

          if(_.isArray(resp.data)){
            self.$data.jobworkers =  resp.data;
          }

        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });

    },
  }
}
</script>

<style scoped>
.modal-full {
  min-width: 100%;
  margin: 0;
}

.modal-full .modal-content {
  min-height: 100vh;
}

  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
